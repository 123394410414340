
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.searchbar {
  &__container {
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex: 1;
    @include respond-to($lg-up) {
      width: auto;
      margin: 0;
    }
  }
  &__headerContainer {
    position: relative;
    display: flex;
    width: 100%;
    @include respond-to($lg-up) {
      max-width: 420px;
    }
  }

  &__modalButton {
    display: flex;
    align-items: center;
    cursor: text;
    background-color: #f2f3f4;
    width: 100%;
    padding: 12px;
    color: #ababab;
    font-size: 14px;
    @include respond-to($lg-up) {
      color: $vc-color-black-off;
      background: $vc-color-g5;
      padding: 0.75rem;
      border-radius: 33px;
      position: relative;
    }
    &--filled {
      @include respond-to($lg-up) {
        color: black;
      }
    }
  }

  &__modalButtonIcon {
    --size: 20px;
    display: inline-flex;
    width: var(--size);
    height: var(--size);
    vertical-align: top;
    margin-right: 10px;
    color: $vc-color-grey-dark;
  }

  &__modal {
    display: flex;
    background-color: white;
    position: absolute;
    left: 0;
    top: -59px;
    box-shadow: 30px 20px 40px rgba(0, 0, 0, 0.2);
    transition: width 1s ease;
    z-index: 10;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    pointer-events: none;
    @include respond-to($lg-up) {
      width: 380px;
      top: -34px;
      left: -32px;
      height: auto;
      overflow-y: auto;
      opacity: 0;
      pointer-events: none;
    }
    &--visible {
      opacity: 1;
      pointer-events: auto;
    }
    &--fullWidth {
      @include respond-to($lg-up) {
        width: 900px;
      }
    }
  }
  &__innerWrapper {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    @include respond-to($lg-up) {
      padding: 1.2rem 4rem 1rem 2.8rem;
    }
  }
  &__closeButton {
    top: 1.4rem;
    right: 1.2rem;
    @include respond-to($lg-up) {
      top: 1.5rem;
      right: 1.5rem;
    }
  }
  &__inputWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 1.125rem;
    position: relative;

    & > span {
      width: 21px;
    }
  }
  &__input {
    margin-left: -2px;
    width: 100%;
    height: 30px;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: $vc-font-body;
    font-weight: 400;

    @include respond-to($lg-up) {
      font-size: 14px;
      border-right: 1px solid #ebebeb;
    }
  }
  &__clearButton {
    background-color: transparent;
    color: #ababab;
    font-size: 15px;
    position: absolute;
    transform: translate(-50%, 50%);
    right: -0.5rem;

    &--open {
      right: 0;
      top: -0.5rem;
    }
  }
  &__suggestions {
    @include respond-to($lg-up) {
      display: flex;
      flex-direction: row;
      min-height: 320px;
    }
  }
  &__suggestionsWrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    flex: 1;
  }

  &__suggestionsTitle {
    color: #ababab;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    margin-bottom: 1rem;
    border-bottom: solid 1px #e8e8e8;
    padding-bottom: 0.5rem;

    @include respond-to($lg-up) {
      margin-bottom: 0.5rem;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &__resultList {
    display: flex;
    flex-direction: column;

    @include respond-to($lg-up) {
      padding-right: 1rem;
    }
  }
  &__backButton {
    --size: 15px;
    position: relative;
    height: var(--size);
    width: var(--size);
    margin-right: 10px;
  }
  &__backButtonIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 12px;
    height: 12px;
    border-bottom: solid 1px #ababab;
    border-left: solid 1px #ababab;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center;
  }
  &__resultListItem {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;

    @include respond-to($lg-up) {
      margin-bottom: 0.5rem;
    }
  }

  &__suggestionsPlaceholder {
    display: flex;
    flex: 1;
  }

  &__recentSearchPlaceholder {
    display: flex;
    flex: 1;
    @include respond-to($lg-up) {
      min-width: 41px;
      margin-right: 1.5rem;
    }
  }
}
