
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.tracer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 2px;
  transition: 0.2s ease-out transform, 0.2s ease-out width;
  z-index: $sideNav-depth;
  background: $vc-color-brand;
  transform: scaleX(0);
  transform-origin: bottom left;
  pointer-events: none;
}
