
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
$border-bottom: 1px solid $vc-color-grey-light;

$desktop-height: 8.25rem;

$header-tooltip-depth: 100;

.mainHeader {
  position: sticky;
  top: 0;
  height: 3rem;
  background: #fff;
  border-bottom: $border-bottom;
  z-index: $navigation-depth;

  @include respond-to($lg-up) {
    position: sticky;
    top: 0;
    height: $desktop-height;
    display: flex;
    flex-direction: column;
  }

  &--navOpen {
    z-index: $modal-depth;
  }

  &.withBanner {
    margin-top: 2.5rem;
  }

  &__innerWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--mainLine {
      justify-content: space-between;
      z-index: 10;
    }

    &--secondLine {
      justify-content: flex-start;
    }
  }

  &__mainLine {
    width: 100%;
    height: 100%;

    @include respond-to($lg-up) {
      border-bottom: $border-bottom;
      min-height: 70px;
    }
  }

  &__secondLine {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
    @media (max-width: #{upper-bound($md-range)}) {
      position: fixed;
      z-index: $sideNav-depth;
      top: 0;
      right: 0;
      bottom: 0;
      left: -100%;
      max-width: 100vw;
      background: #fff;
      transition: left 0.3s;
      &--opened {
        left: 0;
      }
    }
    @include respond-to($lg-up) {
      position: relative;
      box-sizing: border-box;
      width: 100%;
    }
  }

  &__mobileNavigationButton {
    padding: 0.3125rem 0.625rem;
    border: $border-bottom;
    border-radius: 8px;
    position: relative;
    display: flex;
    overflow: visible;
    @include respond-to($lg-up) {
      display: none;
    }
  }

  &__mobileNavigationButtonIcon {
    display: flex;
    width: 1.25rem;
    height: 1.625rem;
  }

  &__searchbarContainer {
    display: flex;
    position: relative;
    @include respond-to($lg-up) {
      justify-content: flex-start;
      flex: 1;
    }
  }

  &__userPanelAndCart {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 32px;

    @include respond-to($lg-up) {
      flex: 1;
      gap: 2rem;
    }
    /* The gap is different between authenticated and non-auth views */
    &--isAuthenticated {
      @include respond-to($lg-up) {
        gap: 1.75rem;
      }
    }
  }

  &__mobileNavigation {
    position: fixed;
    z-index: $sideNav-depth;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100vw;
    background: #fff;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    &--opened {
      transform: translateX(0);
    }
  }

  &__mobileNavigationInnerWrapper {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }
}

:export {
  desktopHeight: $desktop-height;
}
