
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.logo {
  display: flex;
  width: 100%;
  max-width: 10rem;
  @include respond-to($md-up) {
    max-width: 16rem;
  }
  @include respond-to($lg-up) {
    max-width: 243px;
  }
}

.logo__image {
  width: 100%;
  aspect-ratio: attr(width) / attr(height);
}
