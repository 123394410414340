
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
@mixin detailsMarker {
  // Remove native html marker
  &::marker,
  &::-webkit-details-marker {
    display: none;
    // Older browser hack
    content: '';
  }
}

// Potential solution is to use <Icon> element instead
@mixin chevron {
  // Chevron marker
  &::after {
    --size: 0.4375rem;
    --border: 1px solid #888;
    content: '';
    display: flex;
    position: absolute;
    top: 45%;
    right: 1.25rem;
    width: var(--size);
    height: var(--size);
    border-left: var(--border);
    border-bottom: var(--border);
    transform-origin: center center;
    transform: rotate(-45deg);
    transition: transform 0.3s ease;

    // Reuse the same chevron on both mobile and desktop
    @include respond-to($lg-up) {
      top: 2px;
      right: 4px;
    }
  }
}

.desktop {
  display: none;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100%;
  // Hides the scrollbar for firefox
  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  @include respond-to($lg-up) {
    display: flex;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  // Used for first row list items to apply additional padding if we don't render details tag
  &__noChildren {
    padding-right: 2rem !important;
  }

  & > ul {
    display: flex;
    width: 100%;

    & > li {
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      padding-right: 0.5rem;
      display: inline-flex;
      align-items: center;

      & > details {
        margin-left: 0.5rem;

        & summary {
          position: relative;
          pointer-events: none;

          &:focus {
            & span {
              visibility: visible;
            }
          }

          @include detailsMarker;
          & span {
            display: flex;
            visibility: hidden;
            --size: 1rem;
            width: var(--size);
            height: var(--size);
            @include chevron;
          }
        }
      }

      & > a,
      p {
        display: inline-flex;
        font-size: 0.9375rem;
        line-height: 1.125rem;
        padding: 1.25rem 0;
        white-space: nowrap;
      }

      & > p {
        cursor: default;
      }
    }
  }

  &__popup {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    background-color: white;
    transition:
      opacity 0.3s,
      pointer-events 0.3s 0.3s;
    padding-top: 2rem;
    padding-bottom: 3.125rem;
    border-bottom: 1px solid $vc-color-grey-light;

    & div {
      display: flex;
    }
  }

  &__linksContainer {
    flex-direction: column;
    padding-right: 3.75rem;
    gap: 0.8125rem;
    opacity: 1;
    width: calc(100% / 6);
    transition: 0.3s opacity ease-out;

    // Column title
    & > a {
      font-weight: 500;
      font-size: 0.8125rem;
      text-transform: uppercase;
      color: $vc-color-grey-dark;
    }

    &--singleColumn {
      width: 50%;
    }
  }

  // Top row links
  &__linkList {
    & a {
      font-size: 0.9375rem;
      line-height: 1.625rem;
      transition: color 0.3s;
      padding: 4px 0;

      &:not(.highlighted):hover {
        color: $vc-orange;
      }
    }

    &--column-3 {
      columns: clamp(170px, 1px, 240px) 6;
    }
  }
}

.fade {
  &-enter {
    opacity: 0.6;
  }

  &-enter-active {
    opacity: 0.6;
  }

  &-exit {
    opacity: 0;
  }

  &-exit-active {
    opacity: 0;
  }
}

.mobile {
  --itemPadding: 1.1875rem 0 1.1875rem 1.25rem;
  display: flex;
  flex-direction: column;

  @include respond-to($md-only) {
    // Bottom padding is required to offset user-access bottom bar
    padding-bottom: 75px;
  }

  @include respond-to($lg-up) {
    display: none;
  }

  // Border on all list items except the last
  & li:not(:last-child) {
    border-bottom: 1px solid $vc-color-grey-light;
  }

  &__topLevelMenu > li {
    padding: 0;

    // Select all direct child anchors
    & > a {
      display: flex;
      padding: var(--itemPadding);
    }

    // Select all non-direct child anchors
    & > * a {
      padding: 0.5rem 0 0.5rem 1.25rem;
    }

    // Remove border from actual links
    & > * .mobile__linkListItem {
      border-bottom: none;
    }
  }

  & details {
    & summary {
      position: relative;
      @include detailsMarker;

      & > a {
        padding: var(--itemPadding);
        width: 80%;
      }

      // Span is needed for the black underline only
      & span {
        position: relative;

        // Black underline
        &::before {
          position: absolute;
          overflow: hidden;
          content: '';
          width: 0;
          display: flex;
          bottom: 0;
          right: 0;
          left: 0;
          height: 1px;
          background: #000;
          transition: width 0.2s ease;
        }
      }

      @include chevron;
    }

    &[open] {
      & > summary {
        // Extend underline
        & span::before {
          width: 100%;
        }

        // Rotate marker
        &::after {
          transform: rotate(-225deg);
        }
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
  }

  // Add progressively larger left paddings to create the illusion of nested dropdown,
  // but keep the actual full width clickable button surface to allow for easier clicks
  & a {
    display: flex;
    width: 100%;
    @for $i from 1 through 3 {
      &[data-level='#{$i}'] {
        padding-left: ($i - 1) * 1.875rem + 1.25rem;
      }
    }
  }

  & a,
  span {
    font-size: 1rem;
  }
}

.highlighted {
  --violet: #691743;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 1.25rem 0.625rem 1.25rem 0;
  border-top: solid 1px transparent;
  line-height: 1.125rem;
  color: var(--violet);
  white-space: nowrap;

  @include respond-to($lg-up) {
    padding: 1.25rem 0 0 0;
    margin-top: 2.5rem;
  }

  & span {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1.875rem;
      height: 1px;
      background-color: $vc-color-grey-light;

      @include respond-to($lg-up) {
        top: -1.25rem;
      }
    }
  }

  &::after {
    content: attr(data-tag);
    display: inline-block;
    position: relative;
    top: 100%;
    left: 0;
    margin-left: 0.625rem;
    padding: 4px 0.375rem;
    border: solid 1px var(--violet);
    line-height: 0.75rem;
    font-size: 0.625rem;
    letter-spacing: 1.1px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

// Shared style for Desktop & Mobile
.mainNav {
  &__item {
    &--highlight {
      color: $vc-color-orange-50;
    }
  }
}
