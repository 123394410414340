
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
@layer vcImage {
  .image {
    width: 100%;
    height: 100%;
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
